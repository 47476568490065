import {useState} from 'react';
import Modal from 'react-bootstrap/Modal'

import './App.css';
import {Button} from 'react-bootstrap';

function App() {
    const [show, setShow] = useState(true);
    const noConfirm = () => {

    };
    const confirm = () => setShow(false);

    const styles = {
        button: {
            borderWidth: '3px',
            padding: '7px',
            marginTop: '20px',
            marginRight: '20px',
            fontWeight: 'bold'
        }
    }

    return (
        <>
            <div className="App" hidden={show}>
                <header className="App-header">
                    <p>Coming soon</p>
                </header>
            </div>

            <div>
                <Modal show={show} style={{padding: '20px', background : 'black', color: 'white', height: '100vh'}}>
                    <Modal.Header>
                        <Modal.Title>
                            <p style={{color: 'red', fontSize: '36px', marginBottom: '30px'}}>Warning!</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            This site is intended exclusively for persons 18 years and older. If you are under the age
                            of
                            18, then please leave this site. This site contains images, videos, audio, text for adults
                            engaged in sexual activities. If accessing and viewing adult content is not legal for you,
                            please leave the site right now.
                        </p>
                        <br/>
                        <p>
                            By continuing and confirming that you are 18 years old and older, you independently confirm
                            access to viewing sexually explicit adult materials, such as adult movies, hardcore adult
                            movies, XXX movies. The choice is yours whether to comply with local laws regarding adult
                            content. By your choice, you take responsibility for any of your actions on the site, the
                            personal consequences of using the site and compliance with public and social norms. The
                            creators of this site and the service providers do not bear any responsibility for your
                            choice
                            to continue using this site.
                        </p>
                        <br/>
                        <p>
                            This site is not intended for sharing with persons under the age of 18 and is intended
                            solely
                            for personal sole use. To agree with the above information, confirm your date of birth.
                        </p>
                    </Modal.Body>
                    <Modal.Footer style={{float: 'right', marginTop: '28px'}}>
                        <Button variant="secondary" onClick={noConfirm} style={Object.assign({borderColor: 'red', color: 'red'}, styles.button)}>
                            <a href="https://www.google.com">I am under 18</a>
                        </Button>
                        <Button variant="secondary" onClick={confirm} style={Object.assign({borderColor: 'green', color: 'green'}, styles.button)}>
                            I am 18 years
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default App;
